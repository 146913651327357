<template>
  <div>
    <!-- <div>
      <TheNavbarHorizontal></TheNavbarHorizontal>
    </div> -->
    <div>
      <vs-col
        class="back-grewond2"
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="4"
        vs-xs="12"
      >
        <br />
        <br />
        <vs-row style="padding: 3%" vs-lg="6" vs-sm="4" vs-xs="12">
          <img src="../../assets/images/doclnia.png" />
          <vs-row vs-lg="6" vs-sm="4" vs-xs="12">
            <h4 style="color: #00c425">
              <br />
              <br />
              Great people make a great place to work.
            </h4>

            <h3 style="color: aliceblue">
              <br />
              <br />

              In Doclinia we believe in the all-time proverb “Health is wealth”
              and therefore we strive to offer high- quality service to our
              patients in their cure journey.
            </h3>
            <h3 style="color: aliceblue">
              <br />
              <br />

              We are in a growth industry operating in an international
              environment with a clear objective to change the health industry
              into an easy and reachable service to any patient, overcoming
              bureaucracies and boundaries. Honesty and integrity are our core
              values we share with our patients, collaborators, and
              shareholders.
            </h3>
            <h3 style="color: aliceblue">
              <br />
              <br />
              If you think that you share the same values, you are passionate
              about what you do, join our team to grow and add a new experience
              to your professional career.
            </h3>
          </vs-row>
        </vs-row>
      </vs-col>
      <!--  -->
      <vs-col type="flex" vs-lg="6" vs-sm="4" vs-xs="12">
        <!--  -->
        <div>
          <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-md="12"
            vs-sm="12"
            vs-xs="12"
            style="padding-top: 20px; margin: 1px"
          >
            <vs-col
              style="overflow: visible; margin-top: -5%"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-md="12"
              vs-sm="12"
              vs-xs="12"
            >
              <vs-card
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="6"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <br />
                <vs-row
                  vs-type="flex"
                  vs-justify="normal"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <h1 style="color: #132953; font-size: 260%">Join our team</h1>

                  <vs-row class="con-sliderr"
                    ><vs-slider color="success"> </vs-slider>
                    <h5 style="color: #132953">
                      Fill this form or send us your CV to
                    <a href="mailto:info@doclinia.com">  <span style="color: #00c425"> info@doclinia.com</span>  </a>   to
                      be part of an innovative digital health services team.
                    </h5>
                  </vs-row>
                </vs-row>
                <br />
                <vs-row
                  vs-lg="10"
                  vs-justify="center"
                  vs-type="flex"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <!--  -->

                    <vs-input
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @keydown.space.prevent
                      label="First Name"
                      v-model="UserName"
                    />
                  </vs-col>
                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <vs-input
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @keydown.space.prevent
                      label="Last Name"
                      v-model="LastName"
                    />
                  </vs-col>

                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <vs-input
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @keydown.space.prevent
                      label="Email"
                      name="Email"
                      v-model="Email"
                    />
                  </vs-col>
                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <vs-input
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @keydown.space.prevent
                      label="Phone Number"
                      name="PhoneNumber"
                      v-model="PhoneNumber"
                    />
                  </vs-col>
                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <vs-input
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @keydown.space.prevent
                      label="Position Applied"
                      name="Position Applied"
                      v-model="PositionApplied"
                    />
                  </vs-col>
                 
                  <vs-col vs-lg="5" vs-md="12" vs-sm="12" vs-xs="9">
                    <vs-upload
                      v-validate="'required|min:5'"
                      data-vv-validate-on="blur"
                      @on-success="successUpload"
                      label="upload"
                      name="Position Applied"
                      action="https://jsonplaceholder.typicode.com/posts/"
                    />
                  </vs-col>
                  <vs-col
                    vs-lg="9"
                    vs-md="12"
                    vs-sm="12"
                    vs-xs="9"
                    style="resize: auto"
                  >
                    <h5>Cover Letter</h5>
                    <vs-textarea v-model="textarea" />
                  </vs-col>
                  <!--  -->
                </vs-row>
                <vs-row vs-justify="center">
                  <vs-button color="success" type="filled">Success</vs-button>
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
//import TheNavbarHorizontal from "../../layouts/components/navbar/TheNavbarHorizontal.vue";
import Footer from "../../components/footer/Footer.vue";

export default {
  data: {
    textarea: "",
  },

  components: {
    //TheNavbarHorizontal,
    Footer,
    // HorizontalNavMenuHeader,
    // TheNavbarVerticalFullPage,
    // VerticalNavMenuGroup
  },
  methods: {
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
  },
};
</script>
<style>
.back-grewond2 {
  background-image: url("../../assets/images/4.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
.vs-con-input-label {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  padding: 3%;
}
.vs-input--label {
  font-size: large;
  color: rgba(0, 0, 0, 0.7);
}

.vs-slider--circle {
  width: 0px;
  height: 0px;
  position: fixed;
  top: -6px;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin: 0;
  z-index: 200;
  background: #00c425;
}
.vs-slider {
  width: 50%;
  height: 4px;
  border-radius: 4px;
  background: #00c425;
  position: relative;
  cursor: pointer;
  border: 0;
  margin: 0;
  display: block;
  overflow: hidden;
}
.con-input-upload {
  background: #f5f5f5;
  width: 277px;
  height: 57px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 15px;
}
/* .vs-input--label {
      font-size: 71%;
      color: #999;
  } */
/*  */

/*  */
</style>
